import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6193c98e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "max-w-[1028px] flex flex-wrap justify-between items-center gap-[20px] place-items-center px-[20px] lg:px-0 container-body" }
const _hoisted_2 = { class: "cursor-default mobile-heading-3 md:desktop-heading-3 img-filter text-color-gray-700 hover:text-color-gray-50" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.companies, (company, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "space-y-3 md:space-y-0 md:space-x-3",
        "data-aos": "zoom-in-up",
        "data-aos-delay": "50",
        "data-aos-offset": "0",
        key: index
      }, [
        _createElementVNode("h2", _hoisted_2, _toDisplayString(company), 1)
      ]))
    }), 128))
  ]))
}