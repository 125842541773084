import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5c57f0cd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative bg-color-bg-1" }
const _hoisted_2 = { class: "flex flex-col items-center pt-[56px] gap-[50px] md:gap-[80px] lg:gap-[100px] z-10 relative" }
const _hoisted_3 = {
  class: "hidden w-full px-[20px] lg:px-0 container-body lg:flex items-center justify-center h-auto",
  "data-aos": "fade-up",
  "data-aos-anchor-placement": "top-bottom"
}
const _hoisted_4 = { class: "flex flex-col text-xl font-bold tracking-wide text-center text-white whitespace-nowrap w-full" }
const _hoisted_5 = { class: "flex relative flex-col w-full min-h-[95px] max-md:max-w-full" }
const _hoisted_6 = { class: "w-full h-[20px] bg-color-bg-2" }
const _hoisted_7 = { class: "flex relative flex-col w-full max-md:max-w-full mt-[-40px]" }
const _hoisted_8 = { class: "flex flex-wrap gap-6 md:gap-9 justify-between items-center w-full min-h-[95px] max-md:max-w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InitialCase = _resolveComponent("InitialCase")!
  const _component_DescriptionCase = _resolveComponent("DescriptionCase")!
  const _component_StepItem = _resolveComponent("StepItem")!
  const _component_CallAction = _resolveComponent("CallAction")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("main", _hoisted_2, [
      _createVNode(_component_InitialCase, {
        titulo: _ctx.selectedProject?.nombre,
        imagenSrc: _ctx.selectedProject?.info.imgtitle
      }, null, 8, ["titulo", "imagenSrc"]),
      _createVNode(_component_DescriptionCase, {
        problema: _ctx.selectedProject?.info.problema,
        objetivo: _ctx.selectedProject?.info.objetivo,
        solucion: _ctx.selectedProject?.info.solucion,
        img: _ctx.selectedProject?.info.imgmedium
      }, null, 8, ["problema", "objetivo", "solucion", "img"]),
      _createElementVNode("section", _hoisted_3, [
        _createElementVNode("header", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", {
                style: _normalizeStyle({ width: _ctx.selectedProject ? `${_ctx.selectedProject.info.estado}%` : '0%' }),
                class: "h-full bg-color-primary-500"
              }, null, 4)
            ]),
            _createElementVNode("nav", _hoisted_7, [
              _createElementVNode("ul", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step) => {
                  return (_openBlock(), _createBlock(_component_StepItem, {
                    key: step.number,
                    number: step.number,
                    status: step.status
                  }, null, 8, ["number", "status"]))
                }), 128))
              ])
            ])
          ])
        ])
      ]),
      _createVNode(_component_CallAction)
    ])
  ]))
}